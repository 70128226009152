<template>
  <div class="panel">
    <panel-title>
      <el-button @click="$router.back()">返回</el-button>
    </panel-title>
    <div class="panel-body toolWrapper">
      <el-row>
        <el-col :span="24">
          <el-form
            ref="bannerDetail"
            :model="toolDetail"
            label-width="150px"
            :rules="rules"
          >
            <el-form-item label="案例标题" prop="summary">
              <el-input
                v-model.trim="toolDetail.summary"
                style="width: 250px;"
                placeholder="最多30个字"
              />
            </el-form-item>
            <el-form-item label="案例工具" prop="toolId">
              <el-input
                v-model.trim="toolDetail.toolId"
                style="width: 250px;"
                @blur="checkToolId(toolDetail.toolId)"
              />
            </el-form-item>
            <el-form-item label="案例正文" prop="content">
              <div id="editorElem" />
            </el-form-item>
            <el-form-item label="上传图片" prop="icon">
              <pics-upload :default-imgs="defaultImgs" :max-post-imgs="1" />
            </el-form-item>
            <el-form-item label="是否测试" prop="testFlag">
              <el-radio v-model.trim="toolDetail.testFlag" :label="true">
                是
              </el-radio>
              <el-radio v-model.trim="toolDetail.testFlag" :label="false">
                否
              </el-radio>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div style="text-align: center;">
        <el-button type="primary" @click="saveData">提交</el-button>
        <el-button @click="$router.back()">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import { panelTitle, picsUpload } from 'components'
import E from 'wangeditor'
var editor
export default {
  components: {
    panelTitle,
    picsUpload,
  },
  data() {
    return {
      route_id: this.$route.params.id,
      load_data: false,
      defaultImgs: [],
      editorContent: '',
      editorContent2: '',
      toolDetail: {
        summary: '',
        toolId: '',
        imgUrl: '',
        guide: '',
        content: '',
        testFlag: '',
      },
      loading: false,
      rules: {
        summary: [
          { required: true, message: 'Please enter within 30 words', trigger: 'blur' },
          { max: 50, message: 'Please enter within 30 words', trigger: 'blur' },
        ],
        toolId: [
          { required: true, message: 'Please enter tool ID', trigger: 'change' },
        ],
        icon: [{ required: true, message: 'Please enter an icon', trigger: 'blur' }],
        guide: [
          { required: true, message: 'Please enter within 150 words', trigger: 'blur' },
          { max: 50, message: 'Please enter within 150 words！', trigger: 'blur' },
        ],
        content: [{ required: true, message: 'Please enter URL', trigger: 'blur' }],
        testFlag: [
          {
            required: true,
            message: 'Please select whether it is a test case',
            trigger: 'change',
          },
        ],
      },
    }
  },
  watch: {
    defaultImgs() {
      this.toolDetail.imgUrl = this.defaultImgs.join(',')
    },
  },
  created() {
    if (this.route_id == 'add') {
      return false
    } else {
      this.getDetial(this.route_id)
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.listenFreshClose)
    editor = new E('#editorElem')
    editor.customConfig.onchange = (html) => {
      this.editorContent = html
      this.toolDetail.content = html
    }
    // 自定义菜单配置
    editor.customConfig.menus = [
      'head', // 标题
      'bold', // 粗体
      'fontSize', // 字号
      'fontName', // 字体
      'italic', // 斜体
      'underline', // 下划线
      'strikeThrough', // 删除线
      'foreColor', // 文字颜色
      'backColor', // 背景颜色
      'list', // 列表
      'justify', // 对齐方式
      'quote', // 引用
      'image',
      'undo', // 撤销
      'redo', // 重复
    ]
    editor.customConfig.uploadImgServer =
      '/apidev/json/file/upload/file_uploadPic.action'
    editor.customConfig.uploadFileName = 'imageFile'
    editor.customConfig.uploadImgParams = {
      imageFileType: 1,
    }
    editor.customConfig.uploadImgHooks = {
      customInsert: function (insertImg, result, editor) {
        var imgUrl = `https://img30.360buyimg.com/pop/${result.message}`
        insertImg(imgUrl)
      },
    }
    editor.create()
  },
  methods: {
    saveData() {
      if (this.allRule()) {
        if (this.route_id == 'add') {
          if (this.defaultImgs.length == 0) {
            this.$message.error('Please upload pictures')
            return
          }
          this.$fetch.api_case
            .caseSave(this.toolDetail)
            .then(({ success, msg, data }) => {
              if (success) {
                this.$message.success('Operation successful')
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              } else {
                this.$message.error(msg)
              }
            })
        } else {
          this.$fetch.api_case
            .caseEdit(this.toolDetail)
            .then(({ success, msg, data }) => {
              if (success) {
                this.$message.success('Operation successful')
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              } else {
                this.$message.error(msg)
              }
            })
        }
      }
    },
    getDetial(id) {
      //   this.$fetch.api_case.caseDetail("", id).then(({ msg, data }) => {
      //     this.toolDetail.id = id;
      //     this.toolDetail.summary = data.summary;
      //     this.toolDetail.toolId = data.toolId;
      //     this.toolDetail.content = data.content;
      //     this.toolDetail.guide = data.guide;
      //     editor.txt.html(data.content);
      //     editor2.txt.html(data.guide);
      //     this.toolDetail.testFlag = data.testFlag;
      //     this.defaultImgs = data.imgUrl.split(",");
      //   });
    },
    checkToolId(id) {
      var reg = /^\d+$/
      if (!reg.test(id)) {
        this.$message.error('please enter a number')
        this.toolDetail.toolId = ''
        return
      }
      this.$fetch.api_tool
        .checkToolId('', id)
        .then(({ success, msg, data }) => {
          if (!success) {
            this.$message.error('Please enter the correct tool ID')
            this.toolDetail.toolId = ''
          }
        })
    },
    allRule() {
      var actualText_num = this.toolDetail.guide
        .replace(/<\/?[^>]*>/g, '')
        .replace(/[ | ]*\n/g, '\n')
        .replace(/\n[\s| | ]*\r/g, '\n')
        .replace(/&nbsp;/gi, '').length
      var actualText_num2 = this.toolDetail.content
        .replace(/<\/?[^>]*>/g, '')
        .replace(/[ | ]*\n/g, '\n')
        .replace(/\n[\s| | ]*\r/g, '\n')
        .replace(/&nbsp;/gi, '').length

      if (!this.toolDetail.summary) {
        this.$message.error('Case Title cannot be empty')
      } else if (this.toolDetail.summary.length > 30) {
        this.$message.error('The case Title cannot exceed 30 words')
      } else if (!this.toolDetail.toolId) {
        this.$message.error('Case ID cannot be empty')
      } else if (
        !this.toolDetail.content ||
        this.toolDetail.content == '<p><br></p>'
      ) {
        this.$message.error('Case body cannot be empty')
      } else if (actualText_num2 > 1000) {
        this.$message.error('The case text cannot exceed 1000 words')
      } else if (
        !this.toolDetail.guide ||
        this.toolDetail.guide == '<p><br></p>'
      ) {
        this.$message.error('Case guide cannot be empty')
      } else if (actualText_num > 150) {
        this.$message.error('The case guide cannot exceed 150 words')
      } else if (this.toolDetail.testFlag === '') {
        this.$message.error('Please select whether it is a test case')
      } else {
        return true
      }
    },
    listenFreshClose(e) {
      e.returnValue = 'You will leave the page and may lose the content you are editing'
    },
  },
}
</script>
<style lang="less" type="text/less" rel="stylesheet/less">
.btnWrapper {
  text-align: center;
  padding-bottom: 20px;
}
.iconWrapper {
  width: 100px;
  height: 100px;
  border: 1px dashed #c0ccda;
  img {
    width: 97px;
    height: 97px;
  }
}
.panel .toolWrapper {
  padding: 30px 130px;
}
</style>
